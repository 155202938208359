input[type=range] {
  -webkit-appearance: none;
  width: 100%;
  margin: 5.3px 0;
}
input[type=range]:focus {
  outline: none;
}
input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 3.4px;
  cursor: pointer;
  box-shadow: 0.9px 0.9px 1px rgba(0, 0, 0, 0), 0px 0px 0.9px rgba(13, 13, 13, 0);
  background: #dfdfdf;
  border-radius: 25px;
  border: 0px solid #dfdfdf;
}
input[type=range]::-webkit-slider-thumb {
  box-shadow: 0px 0px 0px #dfdfdf, 0px 0px 0px #ececec;
  border: 1.2px solid #999999;
  height: 14px;
  width: 14px;
  border-radius: 6px;
  background: #ffffff;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -5.3px;
}
input[type=range]:focus::-webkit-slider-runnable-track {
  background: #ececec;
}
input[type=range]::-moz-range-track {
  width: 100%;
  height: 3.4px;
  cursor: pointer;
  box-shadow: 0.9px 0.9px 1px rgba(0, 0, 0, 0), 0px 0px 0.9px rgba(13, 13, 13, 0);
  background: #dfdfdf;
  border-radius: 25px;
  border: 0px solid #dfdfdf;
}
input[type=range]::-moz-range-thumb {
  box-shadow: 0px 0px 0px #dfdfdf, 0px 0px 0px #ececec;
  border: 1.2px solid #999999;
  height: 14px;
  width: 14px;
  border-radius: 6px;
  background: #ffffff;
  cursor: pointer;
}
input[type=range]::-ms-track {
  width: 100%;
  height: 3.4px;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  color: transparent;
}
input[type=range]::-ms-fill-lower {
  background: #d2d2d2;
  border: 0px solid #dfdfdf;
  border-radius: 50px;
  box-shadow: 0.9px 0.9px 1px rgba(0, 0, 0, 0), 0px 0px 0.9px rgba(13, 13, 13, 0);
}
input[type=range]::-ms-fill-upper {
  background: #dfdfdf;
  border: 0px solid #dfdfdf;
  border-radius: 50px;
  box-shadow: 0.9px 0.9px 1px rgba(0, 0, 0, 0), 0px 0px 0.9px rgba(13, 13, 13, 0);
}
input[type=range]::-ms-thumb {
  box-shadow: 0px 0px 0px #dfdfdf, 0px 0px 0px #ececec;
  border: 1.2px solid #999999;
  height: 14px;
  width: 14px;
  border-radius: 6px;
  background: #ffffff;
  cursor: pointer;
  height: 3.4px;
}
input[type=range]:focus::-ms-fill-lower {
  background: #dfdfdf;
}
input[type=range]:focus::-ms-fill-upper {
  background: #ececec;
}
