$whiteTwo: #efefef;
$warmGrey: #999999;
$primary: #3a638a;

.switch {
  position: relative;
  display: inline-block;
  width: 38.5px;
  height: 22px;
}

/* Hide default HTML checkbox */
.switch input {display:none;}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $warmGrey;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 2px;
  bottom: 2px;
  background-color: $whiteTwo;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: $primary;
}

input:focus + .slider {
  box-shadow: 0 0 1px $whiteTwo;
}

input:checked + .slider:before {
  -webkit-transform: translateX(16px);
  -ms-transform: translateX(16px);
  transform: translateX(16px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}